import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { UsuarioService } from '../usuario/usuario.service';
import { CookieService } from 'ngx-cookie-service';
import swal from 'sweetalert2';

@Injectable()
export class AdminGuard implements CanActivate {
  
	constructor(
		public _usuarioService: UsuarioService,
		public router: Router,
    private _cookieService: CookieService  
	) { }

  canActivate() {
    let token = this._cookieService.get('token');

    if (token != this._cookieService.get('token')) {

     console.log('admin.guard.ts: token != this._cookieService.get("token"))');

      swal({
        type: 'error',
        title: 'Sesión expirada',
        showConfirmButton: false,
        timer: 2000
      })
      
      setTimeout(() => {
        this._usuarioService.logout();
      }, 2000);
      
      this._usuarioService.cargando = false;
      return false;
    }

  	if (this._usuarioService.usuario.role === 'ADMIN_ROLE' || this._usuarioService.usuario.role === 'MANAGER_ROLE' ) {

      this._usuarioService.renuevaToken()
        .subscribe( ()=> {
        }, ()=> {
          //this.router.navigate(['/ingresar']);
          this._usuarioService.logout();
          this._usuarioService.cargando = false;
        })
  		return true;

  	} else {
      this.router.navigate(['/dashboard']);
  		return false;
  	}
  }
}