
export const URL_SERVICIOS = 'https://nutriser-app.herokuapp.com'; //PROD
//export const URL_SERVICIOS = 'https://nutriser-test.herokuapp.com'; //TEST
//export const URL_SERVICIOS = 'http://localhost:8080';


export const URL_DOCUMENTOS = 'https://nutriser-documents.s3.amazonaws.com'; //PROD
//export const URL_DOCUMENTOS = 'https://nutriser-documents-test.s3.amazonaws.com'; //TEST
//https://nutriser-documents-test.s3.amazonaws.com/usuarios/


//revisar llamadas a documentos de aws en html