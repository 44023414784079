import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URL_SERVICIOS } from '../../config/config';
import { Usuario, Pago, Cuenta } from '../../models/model.index';
import { UsuarioService } from '../usuario/usuario.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class PagoService {

  usuario: Usuario;
  pago: Pago;
  token: string;

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    private _cookieService: CookieService
  ) {
    //this.cargarStorage();
  }

  /*cargarStorage() {

    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));

    } else {
      this.token = '';
      this.usuario = null;

    }
  }*/

  /*===============================
  =            CUENTAS            =
  ===============================*/
  crearCuenta(cuenta: Cuenta) {

    let url = URL_SERVICIOS + '/cuenta?token=' + this._cookieService.get('token');

    return this.http.post(url, cuenta).pipe(
      map((resp: any) => {

        return resp.cuenta;
      }));
  }

  cargarCuenta(id) {
    let url = URL_SERVICIOS + '/cuenta/' + id;

    return this.http.get(url);
  }

  cargarCuentaByTurno(id) {
    let url = URL_SERVICIOS + '/cuenta/consulta/' + id;
    return this.http.get(url);
  }

  cargarPacienteCuentas(id) {
    let url = URL_SERVICIOS + '/cuenta/paciente/' + id;
    return this.http.get(url);
  }

  cargarPacientesCuentas(ids) {
    var array = JSON.stringify(ids)
    let url = URL_SERVICIOS + '/cuenta/pacientes/' + array;
    return this.http.get(url);
  }

  cargarPacientesCuentasAll() {
    let url = URL_SERVICIOS + '/cuenta/pacientesAll';
    return this.http.get(url);
  }

  cargarCuentas(tipo = '', year?) {
    if (!year) {
      let url = URL_SERVICIOS + '/cuenta?tipo=' + tipo;
      return this.http.get(url);
    }
    else {
      let url = URL_SERVICIOS + '/cuenta?tipo=' + tipo + '&&year=' + year;
      console.log('url', url);
      return this.http.get(url);
    }
  }

  actualizarCuenta(cuenta: Cuenta) {

    let url = URL_SERVICIOS + '/cuenta/' + cuenta._id;
    url += '?token=' + this._cookieService.get('token');

    console.log(JSON.stringify(cuenta));

    return this.http.put(url, cuenta).pipe(
      map((resp: any) => {

        //console.log('hola vengo del service');
        return true;
      }));
  }

  /*borrarCuenta( id: string ) {

    let url = URL_SERVICIOS + '/cuenta/' + id + '?token=' + this._usuarioService.token;

    return this.http.delete( url )
      .map( resp => {
        swal({
          type: 'success',
          title: 'Cuenta borrado',
          text: 'El cuenta se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        //console.log('cuenta borrada');
        return true;
      })
  }*/

  /*=============================
  =            PAGOS            =
  =============================*/
  crearPago(pago: Pago) {

    let url = URL_SERVICIOS + '/pago?token=' + this._cookieService.get('token');

    return this.http.post(url, pago).pipe(
      map((resp: any) => {

        return resp.pago;
      }));
  }

  cargarPago(id) {
    let url = URL_SERVICIOS + '/pago/' + id;

    return this.http.get(url);
  }

  cargarPacientePagos(id) {
    let url = URL_SERVICIOS + '/pago/paciente-pago/' + id;

    return this.http.get(url);
  }

  cargarPagos() {
    let url = URL_SERVICIOS + '/pago';

    return this.http.get(url);
  }

  cargarRango(desde, hasta) {
    let url = URL_SERVICIOS + '/pago/' + desde + '/' + hasta;
    console.log(url);
    return this.http.get(url);
  }

  actualizarPago(pago: Pago) {

    let url = URL_SERVICIOS + '/pago/' + pago._id;
    url += '?token=' + this._cookieService.get('token');

    return this.http.put(url, pago).pipe(
      map((resp: any) => true));
  }


  buscarPago(termino: string) {

    let url = URL_SERVICIOS + '/busqueda/coleccion/pagos/' + termino;
    return this.http.get(url).pipe(
      map((resp: any) => resp.pagos));
  }

  borrarPago(id: string) {

    let url = URL_SERVICIOS + '/pago/' + id + '?token=' + this._cookieService.get('token');

    return this.http.delete(url).pipe(
      map(resp => {
        swal({
          type: 'success',
          title: 'Pago borrado',
          text: 'El pago se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
  }

}