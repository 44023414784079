import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UsuarioService, SharedService } from '../../services/service.index';
import { Usuario } from '../../models/usuario.model';

import { URL_DOCUMENTOS, URL_SERVICIOS } from '../../config/config';

import { ToastrService } from 'ngx-toastr';
import * as io from 'socket.io-client';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  private socket;
  usuario: Usuario;
  notificaciones: any[] = [];
  heartbit: boolean = false;
  notificaciones_count: number  = 15;
  imagenTemp: any;

  constructor( 
    public _usuarioService: UsuarioService,
    public _sharedService: SharedService,
    public toastrService: ToastrService,
    public router: Router,
  ) {

    this.usuario = _usuarioService.usuario;

    let profesional: any = localStorage.getItem('usuario');
    profesional = JSON.parse(profesional);

    this.socket = io.connect(URL_SERVICIOS);
    this.socket.on('new notification', (data) => {

      // si la Notification la envio la secretaría
      if (data.role === 'MANAGER_ROLE' || data.role === 'ADMIN_ROLE') {
        
        // si la notificacion es para YO el profesional
        if (data.profesional === profesional._id) {

          setTimeout(() => {
            this.getNotisProfesional();
            this.heartbit = true;
          }, 1000);

          toastrService.success( data.titulo + ' <br><small>' + data.paciente +'</small>', '', {
            closeButton: true,
            disableTimeOut: true,
            enableHtml: true
          });
        }

      } else if (data.role === 'USER_ROLE' && _usuarioService.usuario.role != data.role) {
        
        setTimeout(() => {
          this.getNotisAdmin();
          this.heartbit = true;
        }, 1000);

        toastrService.info( data.paciente + ' <br><small>con ' + data.profesional_nombre +'</small>', 'Confirmar turno', {
          closeButton: true,
          disableTimeOut: true,
          enableHtml: true
        });
      }

    });

  }
  
  ngOnInit() {
		this.usuario = this._usuarioService.usuario;

    if (this.usuario.role === 'ADMIN_ROLE') {
      //console.log('soy Admin');
      this.getNotisAdmin();
    } else {
      //console.log('soy Profesional');
      this.getNotisProfesional();
    }
    if(this.usuario.img){
			this.imagenTemp = URL_DOCUMENTOS + "/usuarios/" + this.usuario._id + "/" + this.usuario.img;			
		};
  }

  getNotisAdmin() {

    //console.log('getNotisAdmin');

    this._sharedService.cargarAdminNotificaciones()
      .subscribe( (resp: any) => {
        //console.log(resp.notis);
        this.notificaciones = resp.notis;
        //console.log(this.notificaciones);
      })

  }

  getNotisProfesional() {

    //console.log('getNotisProfesional');

    this._sharedService.cargarNotificaciones(this.usuario._id)
      .subscribe( (resp: any) => {
        //console.log(resp.notis);
        this.notificaciones = resp.notis;
        //console.log(this.notificaciones);
      })

  }

  markAsRead( noti ) {

    noti.read = true;
    this._sharedService.actualizarNotificacion(noti)
      .subscribe( resp => true)

  }

  buscar( termino: string ) {
  	this.router.navigate(['/busqueda', termino]);
  }
  increaseNotiCount() {
    this.notificaciones_count = this.notificaciones_count + 15
  }
  
}