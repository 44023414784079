import { Component, OnInit } from '@angular/core';

import { SidebarService, UsuarioService } from '../../services/service.index';
import { Usuario } from '../../models/usuario.model';
import { URL_DOCUMENTOS } from '../../config/config';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {
  imagenTemp: any;
	usuario: Usuario;

  constructor( 
    public _sidebar: SidebarService,
    public _usuarioService:UsuarioService
  ) {

  }

	ngOnInit() {
		this.usuario = this._usuarioService.usuario;

    if(this.usuario.img){
			this.imagenTemp = URL_DOCUMENTOS + "/usuarios/" + this.usuario._id + "/" + this.usuario.img;			
		};

    this._sidebar.cargarMenu();
  }

}
