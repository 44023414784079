import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URL_SERVICIOS } from '../../config/config';
import { Usuario, Modalidad, Tratamiento } from '../../models/model.index';
import { UsuarioService } from '../usuario/usuario.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class ModalidadService {

  usuario: Usuario;
  modalidad: Modalidad;
  token: string;

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    private _cookieService: CookieService
  ) {
    //this.cargarStorage();
  }

  /*cargarStorage() {

    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));

      //console.log('cargarStorage this.token ' + this.token);
      //console.log('cargarStorage this.usuario ' + localStorage.getItem('usuario'));

    } else {
      this.token = '';
      this.usuario = null;

    }
  }*/

  cargarModalidades() {
    let url = URL_SERVICIOS + '/modalidad';

    return this.http.get(url);
  }

  crearModalidad(modalidad: Modalidad) {

    let url = URL_SERVICIOS + '/modalidad?token=' + this._cookieService.get('token');

    return this.http.post(url, modalidad).pipe(
      map((resp: any) => {

        swal({
          type: 'success',
          title: 'Modalidad guardada',
          text: '',
          showConfirmButton: false,
          timer: 2000
        });
        return resp.modalidad;
      }));
  }

  actualizarModalidad(modalidad: Modalidad) {

    let url = URL_SERVICIOS + '/modalidad/' + modalidad._id;
    url += '?token=' + this._cookieService.get('token');

    return this.http.put(url, modalidad).pipe(
      map((resp: any) => {
        return true;
      }));
  }


  buscarModalidad(termino: string) {

    let url = URL_SERVICIOS + '/busqueda/coleccion/modalidads/' + termino;
    return this.http.get(url).pipe(
      map((resp: any) => resp.modalidads));
  }

  borrarModalidad( id: string ) {

    let url = URL_SERVICIOS + '/modalidad/' + id + '?token=' + this._cookieService.get('token');

    return this.http.delete( url ).pipe(
      map( resp => {
        swal({
          type: 'success',
          title: 'Modalidad borrada',
          text: 'La modalidad se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
  }

  /*====================================
  =            Tratamientos            =
  ====================================*/

  cargarTratamientos() {
    let url = URL_SERVICIOS + '/tratamiento';

    return this.http.get(url);
  }

  crearTratamiento(tratamiento: Tratamiento) {
    let url = URL_SERVICIOS + '/tratamiento?token=' + this._cookieService.get('token');

    return this.http.post(url, tratamiento).pipe(
      map((resp: any) => {
        
        swal({
          type: 'success',
          title: 'Tratamiento creado',
          text: tratamiento.nombre,
          showConfirmButton: false,
          timer: 2000
        });
        return resp.tratamiento;
      }));
  }

  eliminarTratamiento( id: string, tratamiento: Tratamiento ) {
    let url = URL_SERVICIOS + '/tratamiento/' + id + '?token=' + this._cookieService.get('token');

    return this.http.put( url, tratamiento ).pipe(
      map( resp => {
        swal({
          type: 'success',
          title: 'Tratamiento borrado',
          text: 'El tratamiento se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
    }
}
