import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URL_SERVICIOS } from '../../config/config';
import { Usuario, Paciente } from '../../models/model.index';
import { UsuarioService } from '../usuario/usuario.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class PacienteService {

  usuario: Usuario;
  paciente: Paciente;
  token: string;

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    private _cookieService: CookieService
  ) {
    //this.cargarStorage();
  }

  /*cargarStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));

    } else {
      this.token = '';
      this.usuario = null;
      
    }
  }*/

  crearPaciente(paciente: Paciente) {
    let url = URL_SERVICIOS + '/paciente?token=' + this._cookieService.get('token');

    return this.http.post(url, paciente).pipe(
      map((resp: any) => {
        //swal('Paciente creado', paciente.email, 'success');
        return resp.paciente;
      }));
  }

  actualizarPaciente(paciente: Paciente) {
    let url = URL_SERVICIOS + '/paciente/' + paciente._id;
    url += '?token=' + this._cookieService.get('token');

    return this.http.put(url, paciente).pipe(
      map((resp: any) => {

        //swal('Paciente actualizado', paciente.nombre, 'success');
        return true;
      }));

  }

  actualizarPacienteDesdeConsulta(paciente: Paciente) {
    console.log("paciente", paciente);
    let url = URL_SERVICIOS + '/paciente/' + paciente._id;
    url += '?token=' + this._cookieService.get('token');

    return this.http.put(url, paciente).pipe(
      map((resp: any) => resp.paciente));
  }

  cargarPacientes(desde, hasta) {
    let url = URL_SERVICIOS + '/paciente?desde=' + desde + '&hasta=' + hasta;

    return this.http.get(url);
  }

  cargarPacientesListado(desde, hasta, dataTablesParameters) {
    let url = URL_SERVICIOS + '/paciente/postPacList?desde=' + desde + '&hasta=' + hasta

    return this.http.post(url, dataTablesParameters).pipe(
      map((resp: any) => resp));
    //return this.http.get(url);
  }

  cargarPacientesDerivadosListado(desde, hasta, dataTablesParameters) {
    let url = URL_SERVICIOS + '/paciente/postPacListDerivados?desde=' + desde + '&hasta=' + hasta

    return this.http.post(url, dataTablesParameters).pipe(
      map((resp: any) => resp));
    //return this.http.get(url);
  }

  cargarPacientesEntity(nombre) {
    let url = URL_SERVICIOS + '/paciente/pac?nombre=' + nombre;

    return this.http.get(url);
  }

  cargarPacientesVigentesCuentas(desde, hasta) {
    let url = URL_SERVICIOS + '/paciente/get/accVig?desde=' + desde + '&hasta=' + hasta;

    return this.http.get(url);
  }
  cargarPacientesVigentesConsultas(desde, hasta) {
    let url = URL_SERVICIOS + '/paciente/get/conVig?desde=' + desde + '&hasta=' + hasta;

    return this.http.get(url);
  }
    cargarTotal() {
    let url = URL_SERVICIOS + '/paciente/get/total';
    return this.http.get(url);
  }

  /*=================================
  =       Paciente (1 solo)         =
  =================================*/
  cargarPaciente(id: string) {
    let url = URL_SERVICIOS + '/paciente/' + id;

    return this.http.get(url);
  }

  buscarPaciente(termino: string) {

    let url = URL_SERVICIOS + '/busqueda/coleccion/pacientes/' + termino;
    return this.http.get(url).pipe(
      map((resp: any) => resp.pacientes));
  }

  borrarPaciente(id: string) {

    let url = URL_SERVICIOS + '/paciente/' + id + '?token=' + this._cookieService.get('token');

    return this.http.delete(url).pipe(
      map(resp => {
        swal({
          type: 'success',
          title: 'Paciente borrado',
          text: 'El paciente se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
  }

  /*================================
  =            Historia            =
  ================================*/
  crearHistoria(historia: any) {
    let url = URL_SERVICIOS + '/historia?token=' + this._cookieService.get('token');

    return this.http.post(url, historia).pipe(
      map((resp: any) => {
        //swal('Historia creada', '', 'success');
        return resp.historia;
      }));
  }

  cargarHistorias(id: string) {
    let url = URL_SERVICIOS + '/historia/paciente/' + id;

    return this.http.get(url);
  }

  actualizarHistoria(historia: any) {
    let url = URL_SERVICIOS + '/historia/' + historia._id;
    url += '?token=' + this._cookieService.get('token');

    return this.http.put(url, historia).pipe(
      map((resp: any) => {

        //swal('Paciente actualizado', paciente.nombre, 'success');
        return true;
      }));

  }

}