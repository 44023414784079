import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../usuario/usuario.service';
import { CookieService } from 'ngx-cookie-service';

import swal from 'sweetalert2';

@Injectable()
export class VerificaTokenGuard implements CanActivate {

	constructor(
		public _usuarioService: UsuarioService,
		public router: Router,
    public _cookieService: CookieService
  ) { }

  canActivate(): Promise<boolean> | boolean {
    let token;
    if(!this._cookieService.get('token')){
      token = this._usuarioService.token;
    }
    else{
      token = this._cookieService.get('token');
    }
  	let payload = JSON.parse( atob( token.split('.')[1] ));
  	let expirado = this.expirado(payload.exp);

  	if (expirado || token != this._cookieService.get('token')) {

      if (expirado) {
        console.log('verifica-token.guard.ts: canActivate() -> token expirado');
        //console.log('token');
        //console.log(token);
        //console.log('localStorage.getItem(token)');
        //console.log(localStorage.getItem('token'));
      }

      if (token != this._cookieService.get('token')) {        
        console.log('verifica-token.guard.ts: canActivate() -> token != localStorage.getItem("token")');
        //console.log('token');
        //console.log(token);
        //console.log('localStorage.getItem(token)');
        //console.log(localStorage.getItem('token'));
      }

      swal({
        type: 'error',
        title: 'Sesión expirada',
        showConfirmButton: false,
        timer: 2000
      })
      
      setTimeout(() => {
        this._usuarioService.logout();
      }, 2000);
      
      this._usuarioService.cargando = false;
  		return false;
  	}

    return this.verificaRenueva(payload.exp);
  }

  verificaRenueva( fechaExp: number ): Promise<boolean> {

    console.log('verifica-token.guard.ts: verificaRenueva()');
    //console.log('soy '+ this._usuarioService.usuario.nombre);

  	return new Promise( ( resolve, reject) => {

  		let tokenExp = new Date( fechaExp * 1000 );
  		let ahora = new Date();

  		ahora.setTime( ahora.getTime() + (4 * 60 * 60 * 1000) );

  		if (tokenExp.getTime() > ahora.getTime()) {
  			
  			resolve(true);
  		} else {

  			this._usuarioService.renuevaToken()
  				.subscribe( ()=> {
  					resolve(true);
  				}, ()=> {
            this._usuarioService.logout();
            this._usuarioService.cargando = false;
  					reject(false);
  				})
  		}

  		//resolve(true);
  	});
  }

  expirado( fechaExp: number ) {

  	let ahora = new Date().getTime() / 1000;

  	if (fechaExp < ahora) {
  		return true;
  	} else {
  		return false;
  	}

  }
}