export class Usuario {
	
	constructor (
		public nombre:string,
		public email:string,
		public password:string,
		public img?:string,
		public rubro?:string,
		public bio?:string,
		public horario?:string,
		public t_time?:number,
		public role:string = 'USER_ROLE',
		public google?: boolean,

		public disabled?:boolean,
		public deleted?:boolean,
		public _id?:string
	) { }
}