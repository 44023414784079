import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URL_SERVICIOS } from '../../config/config';
import { Usuario, Producto, TipoProducto } from '../../models/model.index';
import { UsuarioService } from '../usuario/usuario.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class ProductoService {

  usuario: Usuario;
  producto: Producto;
  token: string;

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    private _cookieService: CookieService
  ) {
    //this.cargarStorage();
  }

  /*cargarStorage() {

    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));

      //console.log('cargarStorage this.token ' + this.token);
      //console.log('cargarStorage this.usuario ' + localStorage.getItem('usuario'));

    } else {
      this.token = '';
      this.usuario = null;

    }
  }*/

  /*========================================
  =            Tipo de producto            =
  ========================================*/
  cargarTipoProducto() {
    let url = URL_SERVICIOS + '/producto-tipo';

    return this.http.get(url);
  }

  crearTipoProducto(tipo: TipoProducto) {
    let url = URL_SERVICIOS + '/producto-tipo?token=' + this._cookieService.get('token');

    return this.http.post(url, tipo).pipe(
      map((resp: any) => {

        //console.log(resp);

        swal('Nuevo tipo de producto creado', tipo.nombre, 'success');
        return resp.tipo;
      }));
  }

  borrarTipoProducto( id: string ) {
    let url = URL_SERVICIOS + '/producto-tipo/' + id + '?token=' + this._cookieService.get('token');

    return this.http.delete( url ).pipe(
      map( resp => {
        swal({
          type: 'success',
          title: 'Tipo de producto borrado',
          text: 'El tipo de producto se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
  }

  crearStockProducto( stock ) {
    let url = URL_SERVICIOS + '/producto-repo?token=' + this._cookieService.get('token');

    return this.http.post(url, stock).pipe(
      map((resp: any) => {

        //console.log(resp);

        //swal('Stock Actualizado', '','success');
        return resp.repo;
      }));
  }

  /*================================
  =            Producto            =
  ================================*/
  crearProducto(producto: Producto) {

    let url = URL_SERVICIOS + '/producto?token=' + this._cookieService.get('token');

    return this.http.post(url, producto).pipe(
      map((resp: any) => {

        //swal('Producto creado', producto.nombre, 'success');
        return resp.producto;
      }));
  }

  actualizarProducto(producto: Producto) {

    let url = URL_SERVICIOS + '/producto/' + producto._id;
    url += '?token=' + this._cookieService.get('token');

    //console.log('service '+ JSON.stringify(producto));

    return this.http.put(url, producto).pipe(
      map((resp: any) => {

        //swal('Producto actualizado', producto.nombre, 'success');
        return true;
      }));

  }

  cargarProductos( desde, hasta ) {
    let url = URL_SERVICIOS + '/producto?desde=' + desde + '&hasta=' + hasta;

    return this.http.get(url);
  }

  cargarProducto(id: string) {
    let url = URL_SERVICIOS + '/producto/' + id;

    return this.http.get(url);
  }

  buscarProducto(termino: string) {

    let url = URL_SERVICIOS + '/busqueda/coleccion/productos/' + termino;
    return this.http.get(url).pipe(
      map((resp: any) => resp.productos));
  }

  borrarProducto( id: string ) {

    let url = URL_SERVICIOS + '/producto/' + id + '?token=' + this._cookieService.get('token');

    return this.http.delete( url ).pipe(
      map( resp => {
        swal({
          type: 'success',
          title: 'Producto borrado',
          text: 'El producto se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
  }

}