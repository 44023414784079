import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import { AgePipe } from './age.pipe';
import { StockPipe } from './stock.pipe';
import { SesionPipe } from './sesion.pipe';
import { CustomCurrency } from './customCurrency.pipe';

@NgModule({
  imports: [ ],
  declarations: [
  	ImagenPipe,
  	AgePipe,
  	StockPipe,
  	SesionPipe,
    CustomCurrency
  ],
  exports: [
  	ImagenPipe,
  	AgePipe,
    StockPipe,
    SesionPipe,
    CustomCurrency
  ]
})
export class PipesModule { }
