import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

//import swal from 'sweetalert2';
import { Usuario } from '../models/usuario.model';
import { UsuarioService } from '../services/service.index';

declare function init_plugins();

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.css']
})
export class RegisterComponent implements OnInit {

	forma: FormGroup;

  constructor(
    public _usuarioService:UsuarioService,
  	public router:Router
  ) { }

  sonIguales( k1:string, k2:string ) {
  	return ( group:FormGroup )=> {

  		let pass1 = group.controls[k1].value;
  		let pass2 = group.controls[k2].value;

  		if (pass1 === pass2) {
  			return null;
  		}

  		return {
  			sonIguales: true
  		}
  	}
  }

  ngOnInit() {

  	init_plugins();

  	this.forma = new FormGroup({
  		nombre: new FormControl( null, Validators.required ),
  		email: new FormControl( null, [Validators.required, Validators.email] ),
  		password: new FormControl( null, Validators.required ),
  		password2: new FormControl( null, Validators.required ),
  		condiciones: new FormControl( false )
  	}, { validators: this.sonIguales( 'password', 'password2') } );

  	// test form
  	/*this.forma.setValue({
  		nombre: 'Nico',
			email: 'nicogaldo@gmail.com',
			password: '123456',
			password2: '123456',
			condiciones: true
  	});*/

  }

  registrarUsuario() {

  	//console.log( 'value ' +this.forma.value.condiciones );
  	//console.log( 'valid ' +this.forma.valid );

		if ( !this.forma.valid ) {
		 	return;
		}

		/*if ( this.forma.value.condiciones === false ) {
			return swal('Oops...', 'Something went wrong!', 'error');
			
		}*/

		let usuario = new Usuario(
			this.forma.value.nombre,
			this.forma.value.email,
			this.forma.value.password,
		);

		this._usuarioService.crearUsuario( usuario )
			.subscribe( resp => this.router.navigate(['/ingresar'] ));

  	//console.log( this.forma );
  }
}