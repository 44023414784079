/*===========================================================================
=            profile settings, nada que ver con la ruta /ajustes            =
===========================================================================*/
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Injectable()
export class SettingsService {

	settings: Settings = {
		themeUrl: 'assets/css/colors/green-dark.min.css',
		theme: 'green-dark'
	}

  constructor( @Inject(DOCUMENT) private _document ) {
  	this.loadSettings();
  }

  saveSettings() {
  	localStorage.setItem('settings', JSON.stringify(this.settings));
  }

  loadSettings() {
  	if (localStorage.getItem('settings')) {
  		this.settings = JSON.parse(localStorage.getItem('settings'));

  		this.setTheme(this.settings.theme);
  	} else {
  		this.setTheme(this.settings.theme);
  	}
  }

  setTheme ( theme:any ) {
  	let url = `assets/css/colors/${ theme }.css`;
  	this._document.getElementById('theme').setAttribute('href', url);

  	this.settings.theme = theme;
  	this.settings.themeUrl = url;

  	this.saveSettings();
  }
}

interface Settings {
	themeUrl:string;
	theme:string;
}
