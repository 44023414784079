import { Pipe, PipeTransform } from '@angular/core';
import { URL_DOCUMENTOS, URL_SERVICIOS } from '../config/config';

@Pipe({
	name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

	transform(img: string, tipo: string = 'usuarios'): any {

		let url = URL_SERVICIOS + '/img';

		if (!img) {
			return url + '/usuarios/xxx';
		}

		switch (tipo) {
			case 'productos':
				url += '/productos/' + img;
				break;

			case 'profesionales':
				url += '/profesionales/' + img;
				break;

			case 'usuarios':
				//url = URL_DOCUMENTOS + "" + img
				//url += '/usuarios/' + img;
				//url = URL_SERVICIOS + '/uploads/files/' + img;
				break;

			case 'historias':
				//return URL_SERVICIOS + '/uploads/files/historias/' + img;
				//return 'https://nutriser-app.s3.amazonaws.com/historias/' + img;
				break;

			default:
				url += '/noexiste/imagen';
				break;
		}
		return url;
	}
}