import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stock'
})
export class StockPipe implements PipeTransform {

  transform( stock: any[]) {

	let total = 0;
	total = stock.map( s => s.restock).reduce( function(tot, sum) {
			return tot + sum;
		}, 0);

    return total;
  }

}
