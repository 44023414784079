import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sesion'
})
export class SesionPipe implements PipeTransform {

  transform(value: any, args?: any): any {

  	console.log(value);
  	
    return null;
  }

}
