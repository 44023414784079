import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'age'
})
export class AgePipe implements PipeTransform {

    transform(value: Date): string {
        let today = moment.utc();
        let birthdate = moment.utc(value);
        let years = today.diff(birthdate, 'years');
        let html:string;

        if (years) {
          html = years.toString();
        } else {
          html = "-";
        }

        return html;
    }
}