import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URL_SERVICIOS } from '../../config/config';
import { Usuario, Consulta } from '../../models/model.index';
import { UsuarioService } from '../usuario/usuario.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class ConsultaService {

  usuario: Usuario;
  consulta: Consulta;
  token: string;

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _cookieService: CookieService
  ) {
    //this.cargarStorage();
  }

  /*cargarStorage() {

    if (this._usuarioService.token) {
      this._usuarioService.token = this._usuarioService.token;
      this.usuario = this._usuarioService.usuario;

      console.log('soy '+ this.usuario.nombre + ' desde el consulta.service.ts');

    } else {
      this._usuarioService.token = '';
      this.usuario = null;

    }
  }*/

  /*===========================
  =            GET            =
  ===========================*/  
  cargarTotal() {
    let url = URL_SERVICIOS + '/consulta/get/total';
    return this.http.get(url);
  }

  cargarConsultas( desde: number = 0, hasta: number = 5 ) {
    let url = URL_SERVICIOS + '/consulta?desde=' + desde + '&hasta=' + hasta;

    return this.http.get(url);
  }

  cargarConsulta(id) {
    let url = URL_SERVICIOS + '/consulta/' + id;

    return this.http.get(url);
  }

  cargarTurnos( desde: string = "2018-01-01" ) {
    let url = URL_SERVICIOS + '/consulta/solo/turnos?desde=' +  desde;
    return this.http.get(url);
  }

  cargarTurnosPro( pro ) {
    let url = URL_SERVICIOS + '/consulta/profesional/' + pro;
    return this.http.get(url);
  }

  cargarTurnosProAndDate( pro , date) {
    let url = URL_SERVICIOS + '/consulta/profesional/' + pro +'/' + date;
    return this.http.get(url);
  }

  /*==============================
  =            SEARCH            =
  ==============================*/
  buscarConsulta(termino: string) {

    let url = URL_SERVICIOS + '/busqueda/coleccion/consultas/' + termino;
    return this.http.get(url).pipe(
      map((resp: any) => resp.consultas));
  }

  cargarConsultaPaciente(id: string) {
    let url = URL_SERVICIOS + '/consulta/paciente/' + id;
    return this.http.get(url);
  }

  cargarTurnosPaciente(id: string) {
    let url = URL_SERVICIOS + '/consulta/paciente/' + id + '?tipo=turnos';
    return this.http.get(url);
  }

  cargarRango( mes, anio, tipo = 'consultas', desde: number = 0, hasta: number = 5  ) {
    let url = URL_SERVICIOS + '/consulta/' + mes + '/' + anio + '?tipo=' + tipo + '&desde=' + desde + '&hasta=' + hasta;
    //console.log(url);
    return this.http.get(url); 
  }

  cargarRangoFechas( desde, hasta, tipo = 'consultas', setPageDesde: number = 0, setPageHasta: number = 5  ) {
    let url = URL_SERVICIOS + '/consulta/rango/' + desde + '/' + hasta + '?tipo=' + tipo + '&desde=' + setPageDesde + '&hasta=' + setPageHasta;
    //console.log(url);
    return this.http.get(url); 
  }

  /*============================
  =            ABM             =
  ============================*/
  crearConsulta(consulta: Consulta) {

    //console.log('creando una consulta soy ' + this.usuario.nombre);
    console.log('creando una consulta estoy ' + consulta);
    let url = URL_SERVICIOS + '/consulta?token=' + this._cookieService.get('token');

    return this.http.post(url, consulta).pipe(
      map((resp: any) => {
        swal({
          type: 'success',
          title: 'Consulta guardada',
          showConfirmButton: false,
          timer: 2000
        });

        return resp.consulta;
      }));
  }
  
  actualizarConsulta(consulta: Consulta) {
    let url = URL_SERVICIOS + '/consulta/' + consulta._id + '?token=' + this._cookieService.get('token');

    return this.http.put(url, consulta).pipe(
      map( (resp: any) => {
        //this.subject.next();
        return resp.consulta;
      }))
  }

  borrarConsulta( id: string ) {

    let url = URL_SERVICIOS + '/consulta/' + id + '?token=' + this._cookieService.get('token');

    return this.http.delete( url ).pipe(
      map( resp => {
        swal({
          type: 'success',
          title: 'Consulta borrada',
          text: 'La consulta se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
  }

}