export { SettingsService } from './settings/settings.service';
export { ExportAsService } from './shared/export-as.service';
export { SharedService } from './shared/shared.service';
export { SidebarService } from './shared/sidebar.service';
export { PagerService } from './shared/pager.service';
export { UsuarioService } from './usuario/usuario.service';
export { PacienteService } from './paciente/paciente.service';
export { ConsultaService } from './consulta/consulta.service';
export { ProductoService } from './producto/producto.service';
export { SubirArchivoService } from './subir-archivos/subir-archivo.service';
export { ModalidadService } from './modalidad/modalidad.service';
export { PagoService } from './pago/pago.service';
export { CajaService } from './caja/caja.service';
export { MomentDateFormatter, CustomDatepickerI18n, I18n } from './shared/datepicker.service';
//guards
export { LoginGuard } from './guards/login.guard';
export { AdminGuard } from './guards/admin.guard';
export { VerificaTokenGuard } from './guards/verifica-token.guard';
