import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';

import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SubirArchivoService {

	constructor(private http: HttpClient) { }

	subirArchivo(archivo: File, tipo: string, id: string) {

		return new Promise((resolve, reject) => {

			let formData = new FormData();
			let xhr = new XMLHttpRequest();

			formData.append('imagen', archivo, archivo.name);

			xhr.onreadystatechange = function() {

				if (xhr.readyState === 4) {

					if (xhr.status === 200) {
						console.log('Imagen subida');
						resolve(JSON.parse(xhr.response));
					} else {
						console.log('Fallo la subida', reject(xhr.response));
						reject(xhr.response);
					}
				}
			};

			let url = URL_SERVICIOS + '/public/' + tipo + '/' + id;

			xhr.open('PUT', url, true);
			xhr.send(formData);
		});
	}

	/*=================================
	=            S3 History Upload            =
	=================================*/
	pushFileToStorage(file: File, pacienteId): Observable<HttpEvent<{}>> {
		const formdata: FormData = new FormData();

		formdata.append('file', file);

		const req = new HttpRequest('POST', URL_SERVICIOS + '/uploads/files/upload', formdata, {
			reportProgress: true,
      responseType: 'text',
      headers: new HttpHeaders({paciente: pacienteId})
		});

		return this.http.request(req);
		}

		getFiles(): Observable<any> {
		return this.http.get(URL_SERVICIOS + '/uploads/files/all');
	}

	/*=================================
	=            S3 Profile Upload            =
	=================================*/
	pushImageProfileFileToStorage(file: File, usuarioId): Observable<HttpEvent<{}>> {
		const formdata: FormData = new FormData();

		formdata.append('file', file);

		const req = new HttpRequest('POST', URL_SERVICIOS + '/uploads/profile/files/upload', formdata, {
			reportProgress: true,
      responseType: 'text',
      headers: new HttpHeaders({usuario: usuarioId})
		});
		console.log("req",req);
		return this.http.request(req);
		}

	/*=================================
	=            S3 Patient Upload            =
	=================================*/
	pushImagePatientFileToStorage(file: File, pacienteId): Observable<HttpEvent<{}>> {
		const formdata: FormData = new FormData();

		formdata.append('file', file);

		const req = new HttpRequest('POST', URL_SERVICIOS + '/uploads/patient/files/upload', formdata, {
			reportProgress: true,
      responseType: 'text',
      headers: new HttpHeaders({paciente: pacienteId})
		});
		console.log("req",req);
		return this.http.request(req);
		}


}