import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { 
  SettingsService,
  SidebarService,
  SharedService,
  ExportAsService,
  PagerService,
  UsuarioService,
  PacienteService,
  ConsultaService,
  ProductoService,
  LoginGuard,
  AdminGuard,
  VerificaTokenGuard,
  SubirArchivoService,
  ModalidadService,
  PagoService,
  CajaService
} from './service.index';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
  	SettingsService,
  	SidebarService,
  	SharedService,
    ExportAsService,
    PagerService,
    UsuarioService,
    PacienteService,
  	ConsultaService,
    ProductoService,
    LoginGuard,
    AdminGuard,
    VerificaTokenGuard,
    SubirArchivoService,
    ModalidadService,
    PagoService,
    CajaService
  ],
  declarations: []
})
export class ServiceModule { }
