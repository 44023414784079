import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { URL_SERVICIOS } from '../../config/config';
import { Usuario } from '../../models/usuario.model';
import { SubirArchivoService } from '../subir-archivos/subir-archivo.service';

import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
//import * as io from 'socket.io-client';

@Injectable()
export class UsuarioService {

  //private socket;
  error_msg: string;
  cargando: boolean = false;

  usuario: Usuario;
  token: string;
  menu: any[] = [];

  constructor(
    public http: HttpClient,
    public router: Router,
    public _subirArchivoService: SubirArchivoService,
    public cookieService: CookieService
  ) {
    this.cargarStorage();
  }

  renuevaToken() {

    let url = URL_SERVICIOS + '/login/renuevatoken'
    url+= '?token=' + this.token;

    return this.http.get( url ).pipe(
      map( (resp: any)=> {

        console.log('usuario.service.ts: renuevaToken()');

        localStorage.setItem('token', resp.token);
        this.token = resp.token;
        return true;
      }),
      catchError( err => {

        console.log('usuario.service.ts: renuevaToken() -> catchError()');
        this.router.navigate(['/ingresar']);
        swal({
          type: 'error',
          title: 'Sesión expirada',
          showConfirmButton: false,
          timer: 2000
        });
        this.cargando = false;
        return observableThrowError(err);
      }),)
  }

  estaLogeado() {
    return (this.token.length > 5) ? true : false;
  }

  cargarStorage() {

    if (localStorage.getItem('token')) {
      console.log('cargarStorage' + localStorage);
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      this.menu = JSON.parse(localStorage.getItem('menu'));
      //console.log('cargarStorage this.token ' + this.token);
      //console.log('cargarStorage this.usuario ' + localStorage.getItem('usuario'));
    } else {
      this.token = '';
      this.usuario = null;
      this.menu = [];
    }
  }

  guardarStorage(id: string, token: string, usuario: Usuario, menu: any) {

    localStorage.setItem('id', id);
    localStorage.setItem('token', token);
    localStorage.setItem('usuario', JSON.stringify(usuario));
    if(menu){
      localStorage.setItem('menu', JSON.stringify(menu));
      this.menu = menu;
    }

    this.token = token;
    this.usuario = usuario;
    

    console.log('guardo token en localstorage');
    console.log(this.usuario.nombre);
  }

  logout() {
    
    this.cargando = false;

    this.token = '';
    this.usuario = null;
    this.menu = [];

    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('menu');

    //let noti;
    //this.socket.emit('logout', noti);

    this.router.navigate(['/ingresar']);
    //location.reload();
  }

  /*loginGoogle(token: string) {

    let url = URL_SERVICIOS + '/login/google';
    //console.log('loginGoogle Gtoken '+token);
    return this.http.post(url, { token }).pipe(
      map((resp: any) => {
        this.guardarStorage(resp.id, resp.token, resp.usuario, resp.menu);
        //console.log('loginGoogle user '+JSON.stringify(resp.usuario));
        //console.log('loginGoogle resp.id '+resp.id);
        //console.log('loginGoogle resp.token '+resp.token);
        return true;
      }));
  }*/

  login(usuario: Usuario, recordar: boolean = false) {

    if (recordar) {
      localStorage.setItem('email', usuario.email);
    } else {
      localStorage.removeItem('email');
    }

    this.error_msg = null;
    this.cargando = true;

    let url = URL_SERVICIOS + '/login';
    return this.http.post(url, usuario).pipe(
      map((resp: any) => {

        if (resp.usuario.disabled) {
          this.error_msg = 'Usuario deshablitado';
          return
        }

        this.cookieService.set('token', resp.token);
        this.guardarStorage(resp.id, resp.token, resp.usuario, resp.menu);
        return resp.usuario.role;

      }),
      catchError( err =>  {
        this.cargando = false;
        this.error_msg = err.error.mensaje;
        return observableThrowError(err);
        
      }),);
  }

  crearUsuario(usuario: Usuario) {

    let url = URL_SERVICIOS + '/usuario';

    return this.http.post(url, usuario).pipe(
      map((resp: any) => {

        swal({
          type: 'success',
          title: 'Usuario creado',
          text: usuario.email,
          showConfirmButton: false,
          timer: 2000
        });
        return resp.usuario;
      }),
      catchError( err =>  {

        this.error_msg = err.error.mensaje;
        return observableThrowError(err);
        
      }),);
  }

  actualizarUsuario(usuario: Usuario) {
console.log("actualizarUsuario");
    let url = URL_SERVICIOS + '/usuario/' + usuario._id;
    url += '?token=' + this.token;

    return this.http.put(url, usuario).pipe(
      map((resp: any) => {
        console.log("resp", resp);
        if ( usuario._id === this.usuario._id ) {
          let usuarioDB: Usuario = resp.usuario;
          this.guardarStorage(usuarioDB._id, this.token, usuarioDB, resp.menu);     

        }

        swal({
          type: 'success',
          title: 'Usuario actualizado',
          text: usuario.email,
          showConfirmButton: false,
          timer: 2000
        });
        return true;

      }));
  }

  cambiarImagen(archivo: File, id: string) {

    this._subirArchivoService.subirArchivo(archivo, 'usuarios', id)
      .then((resp: any) => {
        this.usuario.img = resp.usuario.img;
        swal('Imagen Actualizada', this.usuario.nombre, 'success');
        this.guardarStorage(id, this.token, this.usuario, this.menu);

      })
      .catch(resp => {
        //console.log(resp);
      });
  }

  cargarUsuario(id) {
    let url = URL_SERVICIOS + '/usuario/' + id;
    return this.http.get(url);
  }

  cargarUsuarios() {
    let url = URL_SERVICIOS + '/usuario';
    return this.http.get(url);
  }

  buscarUsuario(termino: string) {
    let url = URL_SERVICIOS + '/busqueda/coleccion/usuarios/' + termino;
    return this.http.get(url).pipe(
      map((resp: any) => resp.usuarios));
  }

  borrarUsuario( id: string ) {

    let url = URL_SERVICIOS + '/usuario/' + id + '?token=' + this.token;

    return this.http.delete( url ).pipe(
      map( resp => {
        swal({
          type: 'success',
          title: 'Usuario borrado',
          text: 'El usuario se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
  }

}