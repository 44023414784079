import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumsComponent } from './breadcrums/breadcrums.component';

import { PipesModule } from '../pipes/pipes.module';
import { ToastrModule } from 'ngx-toastr';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MomentModule } from 'ngx-moment';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		MomentModule,
		PipesModule,
		ToastrModule.forRoot(),
		//NgbModule.forRoot(),
	],
	declarations: [
	    NopagefoundComponent,
	    HeaderComponent,
	    SidebarComponent,
	    BreadcrumsComponent,
	],
	exports: [
	    NopagefoundComponent,
	    HeaderComponent,
	    SidebarComponent,
	    BreadcrumsComponent,
	]
    
})
export class SharedModule { }