import { RouterModule, Routes } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { LoginGuard } from './services/guards/login.guard';

const appRoutes: Routes = [
	{ path: 'ingresar', component: LoginComponent, data: { 'pageTitle': 'Ingresar' } },
	{ path: 'registrarse', component: RegisterComponent, data: { 'pageTitle': 'Registrarse' } },

	{
		path: '',
		component: PagesComponent,
		canActivate: [ LoginGuard ],
		loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
	},

	{ path: '**', component: NopagefoundComponent }
];

export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );