import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customCurrency'
})
export class CustomCurrency implements PipeTransform {

    transform(value: String): string {
        let symbol;
        if (value == "Dólares") {
          symbol = "USD";
        } else {
            symbol = "$";
        }

        return symbol;
    }
}