import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URL_SERVICIOS } from '../../config/config';
import { Usuario, Caja } from '../../models/model.index';
import { UsuarioService } from '../usuario/usuario.service';
import { CookieService } from 'ngx-cookie-service';

import { map } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class CajaService {

  usuario: Usuario;
  caja: Caja;
  token: string;

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _cookieService: CookieService
  ) {
    //this.cargarStorage();
  }

  /*cargarStorage() {

    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));

    } else {
      this.token = '';
      this.usuario = null;

    }
  }*/

  /*====================================
  =          Movimientos caja          =
  ====================================*/
  crearMovimiento(caja: Caja) {

    let url = URL_SERVICIOS + '/caja?token=' + this._cookieService.get('token');

    return this.http.post(url, caja).pipe(
      map((resp: any) => {

        return resp.caja;
      }));
  }

  cargarMovimiento(id) {
    let url = URL_SERVICIOS + '/caja/' + id;

    return this.http.get(url);
  }

  cargarRango( desde, hasta ) {
    let url = URL_SERVICIOS + '/caja/' + desde + '/' + hasta;
    console.log('url', url);
    return this.http.get(url);
  }

  /*=============================================
  =          Traigo PAGOS de pacientes          =
  =============================================*/
  cargarPagoRango( desde, hasta ) {
    let url = URL_SERVICIOS + '/pago/' + desde + '/' + hasta;
    return this.http.get(url);
  }
  /*=====  End of Traigo PAGOS de pacientes  ======*/

  cargarMovimientos() {
    let url = URL_SERVICIOS + '/caja';

    return this.http.get(url);
  }
  
  actualizarMovimiento(caja: Caja) {

    let url = URL_SERVICIOS + '/caja/' + caja._id;
    url += '?token=' + this._cookieService.get('token');

    //console.log(JSON.stringify(caja));

    return this.http.put(url, caja).pipe(
      map((resp: any) => {
        
        return true;
      }));
  }


  buscarMovimiento(termino: string) {

    let url = URL_SERVICIOS + '/busqueda/coleccion/caja/' + termino;
    return this.http.get(url).pipe(
      map((resp: any) => resp.caja));
  }

  borrarMovimiento( id: string ) {

    let url = URL_SERVICIOS + '/caja/' + id + '?token=' + this._cookieService.get('token');

    return this.http.delete( url ).pipe(
      map( resp => {
        swal({
          type: 'success',
          title: 'Pago borrado',
          text: 'El caja se ha eliminado correctamente',
          showConfirmButton: false,
          timer: 2000
        });
        return true;
      }))
  }

  /*====================================
  =        Venta de productos          =
  ====================================*/

}